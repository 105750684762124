import axios from 'axios';
import AuthService from './AuthService';
import { Navigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {

  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const ExcelReaderService = {
  createImportLead: async (importLeadData, userId) => {
    try {
      const response = await axios.post(`${API_URL}/ImportLead/${userId}`, importLeadData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data);
      } else {
        throw new Error('An error occurred while importing leads.');
      }
    }
  },
};


export default ExcelReaderService;