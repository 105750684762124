import React from 'react'
import { useLocation } from 'react-router-dom';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function Clients() {


    
  return (
    <div>
      <h2>Clients</h2>
    </div>
  )
}

export default Clients