import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../modules/Admin/services/AuthService';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import SideBar from '../shared/Sidebar/EmployeeSidebar/Sidebar';
import AdminSidebar from '../shared/Sidebar/AdminSidebar/AdminSidebar';
import SuperAdminSidebar from '../shared/Sidebar/SuperAdminSidebarMenu/SuperAdminSidebar';
import Navbar from '../shared/Navbar/Navbar';
const API_URL = process.env.REACT_APP_API_URL;
 
const AppRoute = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const currentRole = AuthService.getLoggedInRoleId();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);

  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const response= AuthService.refreshTokens();
            
            if (response.data.value) {
              const token = response.data.value.newAccessToken;
              const refreshToken = response.data.value.newRefreshToken;
              const user = jwtDecode(token);
              const userDetail = {
                username: user.UserName,
                UserEmail: user.UserEmail,
                UserId: user.UserId,
                UserRoleId: user.UserRoleId
              };
              AuthService.setRefreshToken(refreshToken,token,userDetail);
              if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
              }
            }
          } catch (refreshError) {
            console.error('Token refresh error:', refreshError);
            handleLogout();
          }

          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    );

    const handleLogout = () => { 
      AuthService.handleLogout(); 
      setLoggedIn(false);
    };

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  if (!AuthService.isAuthenticated()) {
    return <Navigate to="/login" />;
  }


  
  return (

    <div className="main-layout">
     <Navbar/>
     
     {currentRole == Role.SUPERADMIN.roleId ? (
        <SuperAdminSidebar>
          <div className="">{children}
            <footer style={{ marginTop: "20px", padding: "10px", height: "100px", textAlign: "center", backgroundColor: "#f1f1f1" }}>
              <p>&copy; {new Date().getFullYear()} Dream Town Builders and Promoters. All rights reserved.</p>
              <p>
                Designed By: <a href="https://amritweb.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}>Amrit Web (OPC) Pvt. Ltd.</a>
              </p>
            </footer>
          </div>
        </SuperAdminSidebar>
      ) : currentRole == Role.ADMIN.roleId ? (
        <AdminSidebar>
          <div className="">{children}
            <footer style={{ marginTop: "20px", padding: "10px", height: "100px", textAlign: "center", backgroundColor: "#f1f1f1" }}>
              <p>&copy; {new Date().getFullYear()} Dream Town Builders and Promoters. All rights reserved.</p>
              <p>
                Designed By: <a href="https://amritweb.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}>Amrit Web (OPC) Pvt. Ltd.</a>
              </p>
            </footer>
          </div>
        </AdminSidebar>
      ) : (
        <SideBar>
          <div className="">{children}
            <footer style={{ marginTop: "20px", padding: "10px", height: "100px", textAlign: "center", backgroundColor: "#f1f1f1" }}>
              <p>&copy; {new Date().getFullYear()} Dream Town Builders and Promoters. All rights reserved.</p>
              <p>
                Designed By: <a href="https://amritweb.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}>
                  Amrit Web (OPC) Pvt. Ltd.</a>
              </p>
            </footer>
          </div>
        </SideBar>
      )}
    </div>
  );
};



export default AppRoute;
