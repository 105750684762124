import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;


const SupportService = {
    sendEmail: async (data) => {
        try {
          const response = await axios.post(`${API_URL}/Email/SendEmail/`, data);
          return response.data;
        } catch (error) {
          throw error;
        }
      },
  };
  export default SupportService;
  