import React from 'react';

const About = () => {
    return (
        <div className="container mt-4 bg-light">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="text-center mb-4">
                        <h2>About Our REal Estate Software</h2>
                    </div>
                    <p>
                        Welcome to our gym software! This software is designed to help manage various aspects of our gym, from member management to class scheduling and more.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio eu nisi fringilla mattis sed at justo. Duis ut ultricies lectus. Integer rhoncus auctor tellus, id blandit sem malesuada sit amet.
                    </p>
                    <p>
                        Fusce gravida dolor a velit sodales, sit amet varius est auctor. Integer venenatis sollicitudin neque, eu eleifend orci posuere vel.
                    </p>
                    <h3>Features:</h3>
                    <ul>
                        <li>Member Management</li>
                        <li>Class Scheduling</li>
                        <li>Instructor Profiles</li>
                        <li>Billing and Payments</li>
                        <li>Reporting</li>
                    </ul>
                    <p>
                        For more information or to schedule a demo, please contact us at <a href="mailto:info@">info@</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
