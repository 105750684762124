import { toast } from 'primereact/toast';

class ToastService {
    constructor(toastRef) {
        this.toastRef = toastRef;
    }

    showSuccess(summary, detail, life = 3000) {
        this.toastRef.current.show({
            severity: 'success',
            summary: summary,
            detail: detail,
            life: life,
        });
    }

    showError(summary, detail, life = 3000) {
        this.toastRef.current.show({
            severity: 'error',
            summary: summary,
            detail: detail,
            life: life,
        });
    }

    showWarning(summary, detail, life = 3000) {
        this.toastRef.current.show({
            severity: 'warn',
            summary: summary,
            detail: detail,
            life: life,
        });
    }

    showInfo(summary, detail, life = 3000) {
        this.toastRef.current.show({
            severity: 'info',
            summary: summary,
            detail: detail,
            life: life,
        });
    }
}

export default ToastService;
