import React, { useEffect, useRef, useState } from 'react';
import {useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import ToastService from '../../services/ToastService'
import RegisterUserService from '../../services/RegisterUserService';

function AddUser() {
  const { id } = useParams(); 
  const [Roles, setRoles] = useState([]); 
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    contactNumber: "",
    streetAddress: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    panNumber:"",
    panImage:null,
    passportNumber:"",
    aadharNumber:"",
    roleId: "",
  });

  const [formError, setFormError] = useState({});

  const navigate = useNavigate();
  const toast = useRef(null);
  const toastService = new ToastService(toast);

  useEffect(() => {
    if (id) {
      getUserById(id);
    }
    fetchRoles();
  }, [id]); 

  const Role = {
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };
  

  async function getUserById(userId) {
    try {
      const user = await RegisterUserService.getUserById(userId);
      setForm(user);
    } catch (e) {
      console.log(e);
    }
  }

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value,  });
  setFormError((prevErrors) => ({ ...prevErrors, [name]: '' })); 
  };
  

  async function fetchRoles() {
    try {
      const roles = await RegisterUserService.getAllRoles();
      setRoles(roles);
    } catch (e) {
      console.log(e);
    }
  }

  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onload = () => {
        const panImage = reader.result.split(',')[1]; 
        setForm({ ...form, panImage });
        setFormError((prevErrors) => ({ ...prevErrors, panImage: '' })); 
      };
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };
    }
  };

  async function saveUser() {
    const errors = {};
    Object.keys(form).forEach(key => {
      if (!form[key].trim()) {
        errors[key] = `Please enter ${key}`;
      }
    });

    setFormError(errors);
    if (Object.keys(errors).length > 0) {
      console.error('Validation errors:', errors);
      return;
    }

    const userRoles = {
      roleId: form.roleId,
      userId: id || 0,
      isRowDeleted: false,
      createdBy: "1",
      modifiedBy: "1",
      createdOn: new Date(),
      modifiedOn: new Date(),
      isActive: true
    };

    
    const requestData = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      password: form.password,
      contactNumber: form.contactNumber,
      streetAddress: form.streetAddress,
      city: form.city,
      state: form.state,
      country: form.country,
      postalCode: form.postalCode,
      roleId: form.roleId,
      panNumber: form.panNumber,
      panImage: form.panImage,
      passportNumber:form.passportNumber,
      aadharNumber:form.aadharNumber,
      userRoles: [userRoles],
      createdBy:0,
      modifiedBy:0,
      isActive: true
    };

    try {
      if (id) {
        await RegisterUserService.updateUser(id, requestData);
        toastService.showSuccess("Success", "User updated successfully");
      } else {
        await RegisterUserService.createUser(requestData);
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'User added successfully' });
      }
      resetForm();
      navigate("/registeruser");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const validationErrors = error.response.data.errors;
        setFormError(validationErrors);
      } else {
        console.log("Error saving user:", error);
      }
    }
  }

  const validateForm = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 

    Object.keys(form).forEach((key) => {
      if (!form[key]?.trim()) {
        errors[key] = `Please enter ${key}`;
      }
    });
    
    if (form.email && !emailRegex.test(form.email)) {
      errors.email = "Please enter a valid email address";
    }

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      saveUser();
    }
  };

  function resetForm() {
    setForm({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      streetAddress: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      panNumber:"",
      passportNumber:"",
      aadharNumber:"",
      roleId: "",
    });
    setFormError({});
  }

  return (
    <div>
        <Toast ref={toast} />


        <form onSubmit={onSubmit}> 
        <div className="row m-2 p-2">
            <div className="card text-center mx-auto">
                <div className="card-header bg-heading text-white">
                    New Employee
                </div>

                <div className="card-body text-start text-dark">
 
                <div className="form-group row">
          <label className="col-lg-2" htmlFor="firstName">
            First Name
            </label>
            <div className="col-lg-4">
          <input
            type="text"
            name="firstName"
            id="firstName"
            className="form-control"
            value={form.firstName}
            onChange={changeHandler} 
          />
          <p className="text-danger">{formError.firstName}</p>
          </div>
          <label  className="col-lg-2" htmlFor="lastName">Last Name</label>

          <div className="col-lg-4">
          <input
            type="text"
            name="lastName"
            id="lastName"
            className="form-control"
            value={form.lastName}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.lastName}</p>
        </div>
       
        </div>

        <div className="form-group row">
        <label className="col-lg-2"  htmlFor="email">Email</label>
        <div className="col-lg-4">
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            value={form.email}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.email}</p>
          </div>

          <label className="col-lg-2" htmlFor="password">Password</label>
          <div className="col-lg-4">
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            value={form.password}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.password}</p>
        </div>
        </div>

        <div className="form-group row">
        <label className="col-lg-2" htmlFor="contactNumber">Contact Number</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="contactNumber"
            id="contactNumber"
            className="form-control"
            onChange={(e) => {
              if (e.target.value.length <= 13) {
                  changeHandler(e);
              }
          }}
          value={form.contactNumber} 
          maxLength="13"
          />
          <p className="text-danger">{formError.contactNumber}</p>
          </div>

          <label className="col-lg-2" htmlFor="streetAddress">Street Address</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="streetAddress"
            id="streetAddress"
            className="form-control"
            value={form.streetAddress}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.streetAddress}</p>
        </div>
        </div>

        <div className="form-group row">
        <label className="col-lg-2" htmlFor="city">City</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="city"
            id="city"
            className="form-control"
            value={form.city}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.city}</p>
        </div>
 
          <label className="col-lg-2" htmlFor="state">State</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="state"
            id="state"
            className="form-control"
            value={form.state}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.state}</p>
        </div> 
        </div>
        <div className="form-group row">
        <label className="col-lg-2" htmlFor="country">Country</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="country"
            id="country"
            className="form-control"
            value={form.country}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.country}</p>
          </div>
      
          <label className="col-lg-2" htmlFor="postalCode">Postal Code</label>
          <div className="col-lg-4">
          <input
            type="number"
            name="postalCode"
            id="postalCode"
            className="form-control"
            value={form.postalCode}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.postalCode}</p>
        </div> </div>

        <div className="form-group row">
        <label className="col-lg-2" htmlFor="panNumber">PAN Number</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="panNumber"
            id="panNumber"
            className="form-control"
            value={form.panNumber}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.panNumber}</p>
          </div>
       
          <label className="col-lg-2" htmlFor="passportNumber">Passport Number</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="passportNumber"
            id="passportNumber"
            className="form-control"
            value={form.passportNumber}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.passportNumber}</p>
        </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-2" htmlFor="panImage">PAN Image</label>
          <div className="col-lg-4">
            <input
              type="file"
              name="panImage"
              id="panImage"
              className="form-control"
              onChange={onFileChange}
            />
            <p className="text-danger">{formError.panImage}</p>
          </div>

          <label className="col-lg-2" htmlFor="aadharNumber">Aadhar Number</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="aadharNumber"
            id="aadharNumber"
            className="form-control"
            value={form.aadharNumber}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.aadharNumber}</p>
        </div>
        </div>


        <div className="form-group row">
        <label className="col-lg-2" htmlFor="roleId">
          Role
        </label>
        <div className="col-lg-10">
          <select
            name="roleId"
            id="roleId"
            className={`form-control ${formError.roleId ? 'is-invalid' : ''}`}
            value={form.roleId}
            onChange={changeHandler} 
          >
                <option value="">Select Role</option>
                <option value={Role.ADMIN.roleId}>{Role.ADMIN.roleName}</option>
                <option value={Role.MANAGER.roleId}>{Role.MANAGER.roleName}</option>
                <option value={Role.EMPLOYEE.roleId}>{Role.EMPLOYEE.roleName}</option>
              </select>
              {formError.roleId && (
                <div className="invalid-feedback">{formError.roleId}</div>
              )}  
            </div>
          </div>
     
                </div>
                
                <div className="card-footer text-muted bg-light">
                   
                  <button type="submit"  className="btn btn-save btn-dark text-white"> <i className="pi pi-plus"></i>Submit</button>
                  
                </div>
            </div>
        </div>

        </form>








      {/* <h4>Add New Employee</h4>
      <form onSubmit={onSubmit}> 
        <div className="form-group row">
          <label className="col-lg-2" htmlFor="firstName">
            First Name
            </label>
            <div className="col-lg-4">
          <input
            type="text"
            name="firstName"
            id="firstName"
            className="form-control"
            value={form.firstName}
            onChange={changeHandler} 
          />
          <p className="text-danger">{formError.firstName}</p>
          </div>
          <label  className="col-lg-2" htmlFor="lastName">Last Name</label>

          <div className="col-lg-4">
          <input
            type="text"
            name="lastName"
            id="lastName"
            className="form-control"
            value={form.lastName}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.lastName}</p>
        </div>
       
        </div>

        <div className="form-group row">
        <label className="col-lg-2"  htmlFor="email">Email</label>
        <div className="col-lg-4">
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            value={form.email}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.email}</p>
          </div>

          <label className="col-lg-2" htmlFor="password">Password</label>
          <div className="col-lg-4">
          <input
            type="password"
            name="password"
            id="password"
            className="form-control"
            value={form.password}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.password}</p>
        </div>
        </div>

        <div className="form-group row">
        <label className="col-lg-2" htmlFor="contactNumber">Contact Number</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="contactNumber"
            id="contactNumber"
            className="form-control"
            onChange={(e) => {
              if (e.target.value.length <= 13) {
                  changeHandler(e);
              }
          }}
          value={form.contactNumber} 
          maxLength="13"
          />
          <p className="text-danger">{formError.contactNumber}</p>
          </div>

          <label className="col-lg-2" htmlFor="streetAddress">Street Address</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="streetAddress"
            id="streetAddress"
            className="form-control"
            value={form.streetAddress}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.streetAddress}</p>
        </div>
        </div>

        <div className="form-group row">
        <label className="col-lg-2" htmlFor="city">City</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="city"
            id="city"
            className="form-control"
            value={form.city}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.city}</p>
        </div>
 
          <label className="col-lg-2" htmlFor="state">State</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="state"
            id="state"
            className="form-control"
            value={form.state}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.state}</p>
        </div> 
        </div>
        <div className="form-group row">
        <label className="col-lg-2" htmlFor="country">Country</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="country"
            id="country"
            className="form-control"
            value={form.country}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.country}</p>
          </div>
      
          <label className="col-lg-2" htmlFor="postalCode">Postal Code</label>
          <div className="col-lg-4">
          <input
            type="number"
            name="postalCode"
            id="postalCode"
            className="form-control"
            value={form.postalCode}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.postalCode}</p>
        </div> </div>

        <div className="form-group row">
        <label className="col-lg-2" htmlFor="panNumber">PAN Number</label>
        <div className="col-lg-4">
          <input
            type="text"
            name="panNumber"
            id="panNumber"
            className="form-control"
            value={form.panNumber}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.panNumber}</p>
          </div>
       
          <label className="col-lg-2" htmlFor="passportNumber">Passport Number</label>
          <div className="col-lg-4">
          <input
            type="text"
            name="passportNumber"
            id="passportNumber"
            className="form-control"
            value={form.passportNumber}
            onChange={changeHandler}
          />
          <p className="text-danger">{formError.passportNumber}</p>
        </div>
        </div>

        <div className="form-group row">
          <label className="col-lg-2" htmlFor="panImage">PAN Image</label>
          <div className="col-lg-4">
            <input
              type="file"
              name="panImage"
              id="panImage"
              className="form-control"
              onChange={onFileChange}
            />
            <p className="text-danger">{formError.panImage}</p>
          </div>
        </div>


        <div className="form-group row">
        <label className="col-lg-2" htmlFor="roleId">
          Role
        </label>
        <div className="col-lg-10">
          <select
            name="roleId"
            id="roleId"
            className={`form-control ${formError.roleId ? 'is-invalid' : ''}`}
            value={form.roleId}
            onChange={changeHandler} 
          >
                <option value="">Select Role</option>
                <option value={Role.ADMIN.roleId}>{Role.ADMIN.roleName}</option>
                <option value={Role.MANAGER.roleId}>{Role.MANAGER.roleName}</option>
                <option value={Role.EMPLOYEE.roleId}>{Role.EMPLOYEE.roleName}</option>
              </select>
              {formError.roleId && (
                <div className="invalid-feedback">{formError.roleId}</div>
              )}  
            </div>
          </div>

        <button type="submit"  className="btn btn-save btn-dark text-white"> <i className="pi pi-plus"></i>Submit</button>
      </form> */}
    </div>
  );
}

export default AddUser;
