import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import RegisterUserService from '../../services/RegisterUserService'; // Fetch employees
import 'datatables.net';
import $ from 'jquery';
import AuthService from '../../services/AuthService';
import ToastService from '../../services/ToastService';

function Visitors() {
  const [visitors, setVisitors] = useState([]);
  const [employees, setEmployees] = useState([]); 
  const navigate = useNavigate(); 
  const toast = useRef(null);
  const tableRef = useRef(null);
  const [UserId] = useState(AuthService.getCurrentUser().UserId);
  const toastService = new ToastService(toast);


  async function  fetchVisitors() {
      try {
        const userId = UserId;
        const leadsassignedData = await LeadsAssignedService.getAllLeadsAssigned(userId);
        const filteredLeads = leadsassignedData.filter(lead => lead.leadStatusId === 8);
        setVisitors(filteredLeads);
      } catch (error) {
        console.error('Error fetching Visitors leads:', error);
        toastService.showError("Error", "Failed to fetch Visitors data");
      }
    };

    async  function fetchEmployees ()  {
      try {
        const employeesData = await RegisterUserService.getEmployessByRoleId(4); 
        setEmployees(employeesData);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    useEffect(() => {
    fetchVisitors();
    fetchEmployees();

    setTimeout(() => {
      $('#tableDataInterested').DataTable();
    }, 1000);
  }, [UserId, toastService]);



  const renderVisitors = () => {
    return visitors.map((leadassigned) => {
      const lead = leadassigned.lead;

      const employee = employees.find((emp) => emp.id === leadassigned.userId); 
      const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : 'Unknown Employee';

      return (
        <tr
          key={leadassigned.id}
          className={employeeName === 'Unknown Employee' ? 'blur-row' : ''} 
        >
          <td>{leadassigned.conversationComment}</td>
          <td>{lead ? `${lead.firstName} ${lead.lastName}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.email}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.contactNumber}` : 'Unknown Lead'}</td>
          <td>{new Date(leadassigned.leadAssignedDate).toLocaleDateString()}</td>
          <td>{employeeName}</td>
        </tr>
      );
    });
  };

  return (
    <div>
      <Toast ref={toast} />
      <h1>Visitors</h1>

      <div className="p-2 m-2">
        <table id="tableDataInterested" ref={tableRef} className="table display">
          <thead>
            <tr>
              <th>Conversation Comments</th>
              <th>Lead Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Lead Assigned Date</th>
              <th>Employee</th>
            </tr>
          </thead>
          <tbody>{renderVisitors()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default Visitors;
