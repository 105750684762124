import React from 'react';

const Privacy = () => {
    return (
        <div className="container mt-4  bg-light">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="text-center mb-4">
                        <h2>Privacy Policy</h2>
                    </div>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio eu nisi fringilla mattis sed at justo. Duis ut ultricies lectus. Integer rhoncus auctor tellus, id blandit sem malesuada sit amet.
                    </p>
                    <p>
                        Fusce gravida dolor a velit sodales, sit amet varius est auctor. Integer venenatis sollicitudin neque, eu eleifend orci posuere vel.
                    </p>
                    <h3>Information We Collect</h3>
                    <p>
                        We collect information in the following ways:
                    </p>
                    <ul>
                        <li>Information you provide when registering or using our services.</li>
                        <li>Automatically collected information through cookies and similar technologies.</li>
                        <li>Information from third-party sources.</li>
                    </ul>
                    <h3>How We Use Your Information</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio eu nisi fringilla mattis sed at justo. Duis ut ultricies lectus. Integer rhoncus auctor tellus, id blandit sem malesuada sit amet.
                    </p>
                    <h3>Security</h3>
                    <p>
                        We take precautions to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure.
                    </p>
                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:privacy@">privacy@</a>.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
