import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

function LogOut() {
  const navigate = useNavigate();

  function handleLogout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    axios.defaults.headers.common["Authorization"] = "";
    navigate("/login");
  }

  return (
    <div>
      <h2 className="text-dark ">Are you sure you want to logout?
      </h2>
          <button className="btn btn-danger" onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default LogOut;