import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SearchResults() {
  const query = useQuery();
  const searchTerm = query.get('query');

  const location = useLocation();
  const leads = location.state?.leads || [];
  const leadAssigned = location.state?.leadAssigned || [];
  const navigate = useNavigate();

  const handleViewLeadDetails = (id) => {
    navigate(`/leads/${id}`);
  };

  const handleViewLeadAssignedDetails = (id) => {
    navigate(`/leadassigned/${id}`);
  };

  return (
    <div>
            <h1 style={{ 
                textDecoration: 'underline', 
                fontFamily: 'Arial, sans-serif', 
                fontSize: '2rem', 
                fontWeight: 'bold',
                color: '#2c3e50'
              }}>
                Search Results for "{searchTerm}"
              </h1>


      <h2>Leads</h2>
      {leads.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Phone No.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead.id}>
                <td>{lead.firstName} {lead.lastName}</td>
                <td>{lead.address}</td>
                <td>{lead.contactNumber}</td>
                <td>
                  <button 
                    onClick={() => handleViewLeadDetails(lead.id)}
                    className="btn btn-save text-white btn-sm"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No leads found.</p>
      )}

      {/* <h2>Lead Assignments</h2> */}
      {/* {leadAssigned.length > 0 ? (
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Lead ID</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {leadAssigned.map((assignment) => (
              <tr key={assignment.id}>
                <td>{assignment.leadId}</td>
                <td>{assignment.leadStatus?.name || 'N/A'}</td>
                <td>
                  <button 
                    onClick={() => handleViewLeadAssignedDetails(assignment.id)}
                    className="btn btn-save text-white btn-sm"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No lead assignments found.</p>
      )} */}


    </div>
  );
}

export default SearchResults;
