import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../../modules/Admin/services/AuthService';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const isAuthenticated = AuthService.isAuthenticated();
  const currentRole = AuthService.getLoggedInRoleId();
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(parseInt(currentRole))) {
    return <Navigate to="/unauthorized" />;
  }

  return children; 
};

export default ProtectedRoute;
