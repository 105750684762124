import React from "react";
import { useNavigate } from "react-router-dom";

function Unauthorized() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login"); 
  };

  const goToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Unauthorized Access</h1>
      <p></p>
      <h3>You do not have permission to view this page.</h3>
      <button
        className="btn btn-save"
        onClick={goToDashboard} 
        style={{ padding: "10px 20px", marginTop: "20px" }}
      >
        Go to Dashboard
      </button>
      <br/>
      <button
        className="btn btn-danger"
        onClick={handleLogin}
        style={{ padding: "10px 20px", marginTop: "20px" }}
      >
        Go to Login
      </button>
    </div>
  );
}

export default Unauthorized;
