import React, { useEffect, useState, useRef } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import $ from 'jquery';
import 'datatables.net';
import AuthService from '../../services/AuthService';
import RegisterUserService from '../../services/RegisterUserService';
import { MdRepeat } from 'react-icons/md';
import ToastService from '../../services/ToastService';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LeadsAssigned() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);  // New state for "Select All"
  const [LeadsAssigned, setLeadsAssigned] = useState([]);
  const navigate = useNavigate(); 
  const toast = useRef(null);
  const [searchTerm] = useState('');
  const tableRef = useRef(null);
  const [employees, setselectedUser] =  useState([]);
  const queryParam = useQuery();
  const [selectedValue, setSelectedValue] = useState('');
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
  const userRole = AuthService.getLoggedInRoleId(); 
  const [isEmployeeSelectedError, setIsEmployeeSelectedError] = useState(false);
  const toastService = new ToastService(toast);

  useEffect(() => {    
    const fetchLeadsAssigned = async () => {
      try {
        const userId = UserId;
        const leadsassignedData = await LeadsAssignedService.getAllLeadsAssigned(userId);
        setLeadsAssigned(leadsassignedData);
      } catch (error) {
        console.error('Error fetching leads assigned:', error);  
        toastService.showError("Error", "Failed to fetch leads assigned data");
      }
    };
    const fetchEmployee = async()=> {
      try {
        const users = await RegisterUserService.getEmployessByRoleId(4);
        setselectedUser(users);
      } catch (error) { 
        console.error('Error fetching users:', error);  
        toastService.showError("Error", "Failed to fetch users data");
      }
    };
    fetchLeadsAssigned();
    fetchEmployee();
    setTimeout(() => {
      $('#tableData').DataTable();
    }, 1000);
  }, [UserId]);

  const filteredLeadsAssigned = LeadsAssigned.filter((leadassigned) => {
    return (
      leadassigned.conversationComment &&
      leadassigned.conversationComment.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });


  const printLead = (leadassigned) => {
    const printWindow = window.open('', '_blank');
    const lead = leadassigned.lead;
    const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
    const sourceTypeName = sourceType ? sourceType.name : "Unknown";
    const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === leadassigned.leadStatusId);
    const leadStatusName = leadStatus ? leadStatus.name : "Unknown";
    const property = PropertyType.find((propertytype) => propertytype.id === leadassigned.propertyTypeId);
    const propertyTypeName = property ? property.name : "Unknown";
 
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Lead</title>
          <style>
            body { font-family: Arial, sans-serif; padding: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          </style>
        </head>
        <body>
          <h2>Lead Details</h2>
          <table>
            <tr><th>Lead Name</th><td>${lead ? `${lead.firstName} ${lead.lastName}` : 'Unknown Lead'}</td></tr>
            <tr><th>Email</th><td>${lead ? lead.email : 'Unknown Lead'}</td></tr>
            <tr><th>Contact Number</th><td>${lead ? lead.contactNumber : 'Unknown Lead'}</td></tr>
            <tr><th>Lead Start Date</th><td>${lead ? new Date(lead.startDate).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              }) : 'Unknown Lead'}</td></tr>
            <tr><th>Lead Assigned Date</th><td>${new Date(leadassigned.leadAssignedDate).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: true,
              })}</td></tr>
            <tr><th>Source Type</th><td>${sourceTypeName}</td></tr>
            <tr><th>Lead Status</th><td>${leadStatusName}</td></tr>
            <tr><th>Property Type</th><td>${propertyTypeName}</td></tr>
            <tr><th>Conversation Comments</th><td>${leadassigned.conversationComment}</td></tr>
          </table>
          <br>
          <button onclick="window.print()">Print</button>
        </body>
      </html>
    `);
    printWindow.document.close();
  };


  // Handle select all
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedRows(LeadsAssigned.map(lead => lead.leadId)); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };

  const handleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };
  

  const handleChange = (event) => {
    setSelectedValue(event);
    if (event) {
      setIsEmployeeSelectedError(false); 
    }
  };
  

  const sourceTypes = [
    { id: 1, name: "Facebook" },
    { id: 2, name: "Instagram" },
    { id: 3, name: "Reference" },
  ];

  $('#tableData').on('change', '.row-checkbox', function () {
    const rowIndex = $(this).data('row');
    const isChecked = $(this).is(':checked');
    const updatedSelection = isChecked
      ? [...selectedRows, rowIndex]
      : selectedRows.filter(index => index !== rowIndex);
    setSelectedRows(updatedSelection);
  });

  const ReAssignLead = async()=>{
    if (!selectedValue) {
      toastService.showWarning("Warning", "Please select an employee to re-assign the lead");
      return;
    }
    if (selectedRows.length === 0) {
      toastService.showWarning("Warning", "Please select at least one lead to re-assign");
      return;
    }
    
    try {
      await LeadsAssignedService.ReAssignedLeads(selectedValue, selectedRows);
        toastService.showSuccess("Success", "Lead Re-Assigned successfully");
    } catch (error) {
      toastService.showError("Error", "Failed to Assign lead");
    }
  }

  function getStatusClass(leadStatusName) {
    switch (leadStatusName) {
      case 'Interested':
        return 'table-success'; 
      case 'HotClient':
        return 'table-danger'; 
      case 'NotInterested':
        return 'table-info';   
      case 'CallBackLater':
        return 'table-warning'; 
      case 'InvalidNumber':
        return 'table-primary';
      case 'Complete':
        return 'table-primary';
      default:
        return ''; 
    }
  }
  

  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };


  const LeadStatus= [
    { id: 1, name: "HotClient" },
    { id: 2, name: "Interested" },
    { id: 3, name: "NotInterested" },
    { id: 4, name: "PhoneNotPicked" },
    { id: 5, name: "InvalidNumber" },
    { id: 6, name: "ContactAfterSomeTime" },
    { id: 7, name: "CallBackLater" },
    { id: 8, name: "Upcoming Visit" },
    { id: 9, name: "Complete" },
    { id: 10, name: "Visited" },
  ];

  const PropertyType= [
    { id: 1, name: "1BHK" },
    { id: 2, name: "2BHK" },
    { id: 3, name: "3BHK" },
    { id: 4, name: "4BHK" },
    { id: 5, name: "5BHK" },
    { id: 6, name: "Plot" },
    { id: 7, name: "Kothi" },
    { id: 8, name: "Other" },
  ];

  const renderLeadsAssigned = () => {
    return filteredLeadsAssigned.map((leadassigned) => {
      const lead = leadassigned.lead;

      const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
      const sourceTypeName = sourceType ? sourceType.name : "Unknown";
  
      const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === leadassigned.leadStatusId);
      const leadStatusName = leadStatus ? leadStatus.name : "Unknown";

      const property = PropertyType.find((propertytype) => propertytype.id === leadassigned.propertyTypeId);
      const propertyTypeName = property ? property.name : "Unknown";

      const employee = employees.find((emp) => emp.id === leadassigned.userId);
      const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : 'InActive Employee';

      return (
        <tr key={leadassigned.id}>
          {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
          <td>
          <input 
            type="checkbox" 
            checked={selectedRows.includes(leadassigned.leadId)}
            onChange={() => handleRowSelection(leadassigned.leadId)} />
          </td>
          )}
          <td>{leadassigned.conversationComment}</td>
          <td>{lead ? `${lead.firstName}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.email}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.contactNumber}` : 'Unknown Lead'}</td>
          <td>{sourceTypeName}</td>
          <td className={getStatusClass(leadStatusName)}> 
                {leadStatusName}
          </td>
 
          <td>{propertyTypeName}</td> 
          <td>
            {new Date(leadassigned.leadAssignedDate).toLocaleDateString()}
          </td>
          <td>{employeeName}</td>
          <td>{new Date(leadassigned.followUpDate).toLocaleDateString()}</td>
          <td>{new Date(leadassigned.nextUpdate).toLocaleDateString()}</td>
          <td>
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => navigate(`/editleadassigned/${leadassigned.id}`)}
            >
              <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>
            {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
            <button className="btn btn-outline-dark btn-sm" onClick={() => printLead(leadassigned)}>
            <i class="fas fa-print" style={{ fontSize: '1.5rem' }}></i>
            </button>
            )}
          </td>
        </tr>
      );
    });
  };
  

  return (
    <div>
      <Toast ref={toast} />
      <h1>Assigned Leads</h1>

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

      <div className="form-group row">
              <div className="col-lg-6">
                <select
                  name="userId"
                  id="userId"
                  className={`form-control ${isEmployeeSelectedError ? 'error' : (selectedValue ? 'valid' : '')}`}
                  value={selectedValue}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <option value="" disabled>Select an Employee</option>
                  {employees.map((employee) => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName}
                    </option>
                  ))}
                </select>
                {isEmployeeSelectedError && (
              <div className="text-danger mt-2">
                Please select an employee
              </div>
            )}
              </div>

          
                <div className="col-lg-3">
                    <button
                      className="btn btn-save text-white"
                      onClick={() => ReAssignLead()}
                      >
                     <MdRepeat /> Re-Assign Leads
                    </button>                
                </div>
        <div>
          <button
            className="btn btn-save offset-11 text-white"
            onClick={() => {
              navigate("/addleadsassigned");
            }}
          >
            <i className="pi pi-plus"></i> 
            Add
          </button>
        </div>
      </div>
      )} 

      <div className="p-2 m-2">
       
      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
       <div>
       <input
          type="checkbox"
          checked={selectAll}
          onChange={handleSelectAll}
        /> 
        <label>Select All</label>
       </div>
         )} 



      <table id="tableData" ref={tableRef} className="table display">
          <thead>
            <tr>
              {userRole == Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId ? (
                <th>Select</th>
              ) : null}
              <th>Conversation Comments</th>
              <th>Lead Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Source Type</th>
              <th>Lead Status</th>      
              <th>Property Type</th>
              <th>Lead Assigned Date</th>              
              <th>Employee</th>             
              <th>Follow Up</th>
              <th>Next FollowUp</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{renderLeadsAssigned()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default LeadsAssigned;