import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from 'primereact/toast';
import BeatLoader from "react-spinners/BeatLoader";
import { Dialog } from 'primereact/dialog';
import { FaBuilding, FaEye, FaEyeSlash, FaUser } from "react-icons/fa";
import AuthService from "../../modules/Admin/services/AuthService";

const API_URL = process.env.REACT_APP_API_URL;
const otherUrl = process.env.REACT_APP_OTHER_URL;

const Roles = {
  SuperAdmin: 1,
  Admin: 2,
  Manager: 3,
  Employee: 4,
};

function LoginForm() {
 // console.log('API URL:', API_URL);
 // console.log('Other URL:', otherUrl);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); 
  const toast = React.useRef(null);
  const [loading, setLoading] = useState(false);
  const [footerVisible, setFooterVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  useEffect(() => {
    const { email: savedEmail, password: savedPassword } = AuthService.getRememberedCredentials();
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    setTimeout(() => {
      AuthService.login(email, password, rememberMe, navigate, Roles)
        .then(() => {         
        })
        .catch((err) => {
          console.error('Login failed', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 2000);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="bg">
      <Toast ref={toast} />
      <div className="container custom">
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div className="card"  style={{ backgroundColor: "rgb(53 53 53)", color: "#000", opacity: .9 }} >
              <div className="card-header text-bold text-center text-white" style={{ backgroundColor: "#E3B988" }}>
                <h2 className="text-dark text-bold">Dream Town Builders <FaBuilding/></h2>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="text-white" htmlFor="username">Email</label>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Email" className="form-control" id="username" name="username" required />
                  </div>
                  <div className="form-group position-relative">
                      <label className="text-white" htmlFor="password">Password</label>
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        id="password"
                        name="password"
                        required
                      />
                      <span
                        className="position-absolute"
                        style={{ right: '10px', top: '35px', cursor: 'pointer' }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                  <div className="form-group form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label className="form-check-label text-white" htmlFor="rememberMe">Remember Me</label>
                  </div>
                  <button type="submit" className="text-bold btn form-control btn-lg" style={{ backgroundColor: "#E3B988" }} disabled={loading}>
                    <b> <FaUser /> Log In</b>
                  </button>
                </form>
                {loading && (
                  <div className="d-flex justify-content-center mt-3">
                    <BeatLoader color={"#E3B988"} loading={loading} size={30} />
                  </div>
                )}
              </div>
              <div className="card-footer text-center">
                <button className="btn btn-link text-white" onClick={() => setFooterVisible(true)}>
                  © 2024 Dream Town Builders. All Rights Reserved.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog header="Copyright Information" visible={footerVisible} style={{ width: '50vw', height: '20vh' }} modal onHide={() => setFooterVisible(false)}>
        <p>© 2024 Dream Town Builders and Promoters <FaBuilding />. All Rights Reserved. </p>
         <p> This application is developed and 
          designed by: <a href="https://amritweb.in/" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}>
          Amrit Web (OPC) Pvt. Ltd.</a></p>
         <p> Unauthorized duplication or distribution is strictly prohibited.</p>
      </Dialog>
    </div>
  );
}

export default LoginForm;
