import React from 'react';
import { FaHome, FaShieldAlt, FaCreditCard, FaBuilding } from 'react-icons/fa'; 


const WelcomePopup = ({ visible, onClose }) => {
    return (
        <div className={`custom-modal-container ${visible ? 'show' : ''}`}>
            <div className={`custom-modal-backdrop ${visible ? 'show' : ''}`}></div>
            <div className={`custom-modal fade ${visible ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" aria-labelledby="welcomeModal" aria-hidden={!visible}>
                <div className="custom-modal-dialog custom-modal-dialog-centered" role="document">
                    <div className="custom-modal-content custom-modal-opacity">
                        <div className="custom-modal-header">
                            <h3 className="custom-modal-title text-white" id="welcomeModal">
                                Welcome to Dream Town Builders & Promoters! <FaBuilding/>
                            </h3>
                            <button type="button" className="btn-close" aria-label="Close" onClick={onClose}></button>
                        </div>
                        <div className="custom-modal-body">
                                <b>At Dream Town Builders <FaBuilding/>, our legacy is built on trust, reliability, 
                                    and a steadfast commitment to delivering excellence.</b>
                                    <p></p>
                                <p>We are committed to helping you find your dream property and turn it into a reality.</p>
                                <p>Your dreams of a perfect home <FaHome /> come to life with The Perfect Residency. </p>
                                <p><FaHome /> The Perfect Residency</p>
                                <p><FaShieldAlt /> Trusted By Thousands</p>
                                <p><FaCreditCard /> Total Payment Transparency</p>
                                <p>Our legacy is built on trust, reliability, and a steadfast commitment to delivering excellence.</p>
                                <b>Thank you for choosing Dream Town Builders! </b>
                        </div>
                        <div className="custom-modal-footer ">
                            <button type="button" className="btn btn-dark" onClick={onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomePopup;
