import axios from 'axios';
import AuthService from './AuthService';
import { Navigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {

  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const DashboardService =
{
    async getDashboardCards(userId, startDate, endDate) {
        try {
          const response = await axios.get(`${API_URL}/Dashboard/${userId}/${startDate}/${endDate}`);
          const data = response.data;
          return {
            leadCount: data.leadCount,
            interestedCount: data.interestedCount,
            upcomingVisitCount: data.upcomingVisitCount,
            phoneNotPickedCount: data.phoneNotPickedCount,
            invalidNumberCount: data.invalidNumberCount,
            contactAfterSomeTimeCount: data.contactAfterSomeTimeCount,
            callBackLaterCount: data.callBackLaterCount,
            totalCallsCount: data.totalCallsCount,
            leadAssignedCount: data.leadAssignedCount,
            visitedCount: data.visitedCount
          };
        } catch (error) {
          console.error(`Error fetching DashboardCards  with ID ${userId}:`, error);
          throw error;
        }
      },      
};
export default DashboardService;