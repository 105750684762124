import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LeadService from '../../services/LeadService';

function LeadDetails() {
    const { id } = useParams();
    const [lead, setLead] = useState(null);
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchLead = async () => {
            try {
                const response = await LeadService.getLeadById(id);
                setLead(response);
            } catch (error) {
                console.error('Error fetching lead details:', error);
            }
        };

        fetchLead();
    }, [id]);

    if (!lead) {
        return <p>Loading...</p>;
    }

    const sourceTypes = [
        { id: 1, name: 'Facebook' },
        { id: 2, name: 'Instagram' },
        { id: 3, name: 'Reference' }
    ];


    const getSourceTypeName = (id) => {
        const sourceType = sourceTypes.find(type => type.id === id);
        return sourceType ? sourceType.name : 'Unknown';
    };


    const getStatusClass = (sourceTypeName) => {
        switch (sourceTypeName) {
            case 'Facebook':
                return 'table-warning'; 
            case 'Instagram':
                return 'table-danger'; 
            case 'Reference':
                return 'table-info';   
            default:
                return ''; 
        }
    };


    const sourceTypeName = getSourceTypeName(lead.sourceTypeId);
    const statusClass = getStatusClass(sourceTypeName);

    return (
        <div>
            <h1>Lead Details</h1>
            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <th>Lead Code</th>
                        <td>{lead.leadCode || 'N/A'}</td>
                    </tr>
                    <tr>
                        <th>First Name</th>
                        <td>{lead.firstName || 'N/A'}</td>
                    </tr>
                    <tr>
                        <th>Last Name</th>
                        <td>{lead.lastName || 'N/A'}</td>
                    </tr>
                    <tr>
                        <th>Email</th>
                        <td>{lead.email || 'N/A'}</td>
                    </tr>
                    <tr>
                        <th>Contact Number</th>
                        <td>{lead.contactNumber || 'N/A'}</td>
                    </tr>
                    <tr>
                        <th>Start Date</th>
                        <td>{lead.startDate ? new Date(lead.startDate).toLocaleDateString() : 'N/A'}</td>
                    </tr>
                    <tr>
                        <th>Address</th>
                        <td>{lead.address || 'N/A'}</td>
                    </tr>
                    <tr className={statusClass}>
                        <th>Source Type</th>
                        <td>{sourceTypeName || 'N/A'}</td>
                    </tr>
                
                </tbody>
            </table>

            <button className="btn btn-save text-white" onClick={() => navigate('/leads')}>
                Go (Back) to Leads List
            </button>
        </div>
    );
}

export default LeadDetails;
