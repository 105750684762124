import './App.css';
import AppRoute from './utility/AppRoute';
import Privacy from './shared/Privacy/Privacy';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from './modules/Admin/components/Dashboard/Dashboard';
import SearchResults from './shared/Search/SearchResults';
import About from './shared/About/About';
import LoginForm from './shared/Login/LoginForm';
import SupportForm from './shared/Support/SupportForm';
import Leads from './modules/Admin/components/Lead/Leads';
import AddLead from './modules/Admin/components/Lead/AddLead';
import LeadsAssigned from './modules/Admin/components/LeadsAssigned/LeadsAssigned';
import AddLeadsAssigned from './modules/Admin/components/LeadsAssigned/AddLeadsAssigned';
import EditLead from './modules/Admin/components/Lead/EditLead';
import ExcelReader from './modules/Admin/components/Lead/ExcelReader';
import Unauthorized from './shared/Unauthorized/Unauthorized';
import ProtectedRoute from './shared/ProtectedRoute/ProtectedRoute';
import EditLeadAssigned from './modules/Admin/components/LeadsAssigned/EditLeadAssigned';
import LeadDetails from './modules/Admin/components/Lead/LeadDetails';
import PrintLead from './modules/Admin/components/Lead/PrintLead';
import InterestedClients from './modules/Admin/components/Lead/InterestedClients';
import Visitors from './modules/Admin/components/Lead/Visitors';
import PendingFollowUps from './modules/Admin/components/LeadsAssigned/PendingFollowUps';
import Visited from './modules/Admin/components/Lead/Visited';
import AddUser from '../src/modules/Admin/components/Auth/AddUser';
import RegisterUser from '../src/modules/Admin/components/Auth/RegisterUser';
import EditUser from './modules/Admin/components/Auth/EditUser';
import LogOut from './modules/Admin/components/Auth/LogOut';
import Clients from './modules/SuperAdmin/components/Client/Clients';

const Roles = {
  SuperAdmin:1,
  Admin: 2,
  Manager: 3,
  Employee: 4,
};

function App() {
  return (

    <div>
      <Router >
      <Routes>

      <Route path="/login" element={<LoginForm></LoginForm>} />
  
      <Route path="/" element={<AppRoute><Dashboard/></AppRoute>} />

      <Route path="/unauthorized" element={<AppRoute><Unauthorized/></AppRoute>} />

      <Route path="/dashboard" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><Dashboard /></AppRoute>
          </ProtectedRoute>
        } />

      <Route path="/interestedclients" element={
            <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
              <AppRoute><InterestedClients /></AppRoute>
            </ProtectedRoute>
          } />

<Route path="/visited" element={
            <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
              <AppRoute><Visited /></AppRoute>
            </ProtectedRoute>
          } />

<Route path="/pendingfollowups" element={
            <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
              <AppRoute><PendingFollowUps /></AppRoute>
            </ProtectedRoute>
          } />

<Route path="/visitors" element={
            <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
              <AppRoute><Visitors /></AppRoute>
            </ProtectedRoute>
          } />

        <Route path="/settings" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><Dashboard /></AppRoute>
          </ProtectedRoute>
        } />

        <Route path="/printlead" element={
                  <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
                    <AppRoute><PrintLead /></AppRoute>
                  </ProtectedRoute>
                } />


        <Route path="/about" element={
          <ProtectedRoute allowedRoles={[Roles.Employee,Roles.Admin,Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><About /></AppRoute>
          </ProtectedRoute>
        } />

      <Route path="/privacy" element={
        <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
          <AppRoute><Privacy /></AppRoute>
        </ProtectedRoute>
      } />   

       <Route path="/registeruser" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin]}>
            <AppRoute><RegisterUser /></AppRoute>
          </ProtectedRoute>
        } />

      <Route path="/client" element={
        <ProtectedRoute allowedRoles={[ Roles.SuperAdmin]}>
          <AppRoute><Clients /></AppRoute>
        </ProtectedRoute>
      } />

        <Route path="/adduser" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin]}>
          <AppRoute><AddUser /></AppRoute>
          </ProtectedRoute>
        } />

         <Route path="/edituser/:id" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><EditUser /></AppRoute>
          </ProtectedRoute>
        } />

         <Route path="/quicksupport" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><SupportForm /></AppRoute>
          </ProtectedRoute>
        } />

         <Route path="/leads" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><Leads /></AppRoute>
          </ProtectedRoute>
        } />

        <Route path="/importLeads" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><ExcelReader /></AppRoute>
          </ProtectedRoute>
        } />

      <Route path="/editlead/:id" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><EditLead /></AppRoute>
          </ProtectedRoute>
        } />

        <Route path="/addlead" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><AddLead /></AppRoute>
          </ProtectedRoute>
        } />

          <Route path="/leadassigned" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><LeadsAssigned /></AppRoute>
          </ProtectedRoute>
        } />

        <Route path="/addleadsassigned" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><AddLeadsAssigned /></AppRoute>
          </ProtectedRoute>
        } />

        {/* <Route path="/unauthorized" element={
          <ProtectedRoute allowedRoles={[Roles.Employee, Roles.Admin, Roles.SuperAdmin, Roles.Manager]}>
            <AppRoute><Unauthorized /></AppRoute>
          </ProtectedRoute>
        } /> */}

        <Route path="/editleadassigned/:id" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin, Roles.Manager, Roles.Employee]}>
            <AppRoute><EditLeadAssigned /></AppRoute>
          </ProtectedRoute>
        } />

        <Route path="/search" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin, Roles.Manager, Roles.Employee]}>
            <AppRoute><SearchResults /></AppRoute>
          </ProtectedRoute>
        } />

        <Route path="/leads/:id" element={
          <ProtectedRoute allowedRoles={[Roles.Admin, Roles.SuperAdmin, Roles.Manager, Roles.Employee]}>
            <AppRoute><LeadDetails /></AppRoute>
          </ProtectedRoute>
        } />

      <Route path="/logout" element={<AppRoute><LogOut /></AppRoute>} />
     
    </Routes>
  </Router>
       
    </div>
  );
}

export default App;
