import axios from 'axios';
import AuthService from './AuthService';
import { Navigate, useNavigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
 


const LeadService = {
  getAllLeads: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/Lead/GetAllLeads/${userId}`);
      return response.data.$values;
    } catch (error) { 
      console.error('Error fetching leads:', error);
      throw error;
    }
  },

  getLeadById: async (id) => { 
    try {
      const response = await axios.get(`${API_URL}/Lead/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching lead with ID ${id}:`, error);
      throw error;
    } 
  },

  getLeadId:async()=>{
  const response=await axios.get(`${API_URL}/Lead`);
  return response.data.id;
  },

  createLead: async (leadData) => {
    try {
      const response = await axios.post(`${API_URL}/Lead`, leadData);
      return response.data;
    } catch (error) {
      console.error('Error creating Lead:', error);
      throw error;
    }
  },

  updateLead: async (id, leadData) => {
    try {
      const response = await axios.put(`${API_URL}/Lead/${id}`, leadData);
      return response.data;
    } catch (error) {
      console.error(`Error updating lead with ID ${id}:`, error);
      throw error;
    }
  }, 

  deleteLead: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/Lead/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting lead with ID ${id}:`, error); 
      throw error;
    }
  },

getLeadsByUserId: async (userId) => {
  try {
    const response = await axios.get(`${API_URL}/Lead/LeadsByUserId/${userId}`);
    return response.data.$values;
  } catch (error) {
    console.error(`Error fetching leads for user with ID ${userId}:`, error);
    throw error;
  }
}, 

getLeadHistoryByLeadId: async (id) => {
  try {
    const response = await axios.get(`${API_URL}/LeadHistory/GetLeadHistoryByLeadId/${id}`);
    return response.data.$values;
  } catch (error) {
    console.error(`Error fetching leads history ${id}:`, error);
    throw error;
  }
}, 


searchLeads: async (query) => {
  try {
    const response = await axios.get(`${API_URL}/Lead/Search`, {
      params: { searchTerm: query }  
    });
    console.log(response.data.$values);
    return response.data.$values;  
  } catch (error) {
    console.error('Error searching leads:', error);
    throw error;
  }
},

AssignedLeadsToEmployee: async (userId, leadIds) => {
  try {
    const requestBody = {
      leadIds,
      leadAssignedDtos: [ 
        {
          leadStatusId: 9,
          propertyTypeId: 2,
          conversationComment: 'Assigned to employee',
          leadAssignedDate: new Date().toISOString(),
          followUpDate: new Date().toISOString(),
         // nextUpdate:null,
          userId,
        },
      ],
    };

    const response = await axios.post(`${API_URL}/LeadAssigned/AssignedLeadsToEmployee/${userId}/${leadIds}`, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error assigning lead:', error);
    throw error;
  }
},


async RecordStatusChange(leadAssignedId, newStatus, followUpDate, nextUpdate) {
  try {
    debugger
    const response = await axios.put(`${API_URL}/LeadHistory/RecordStatusChange/
                      ${leadAssignedId}/${newStatus}/${followUpDate}/${nextUpdate}`);
    console.log(response.data);
    return response.data; 
  } catch (error) {
    console.error('Error recording status change:', error);
    throw error;
  }
}

};


export default LeadService;
