import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import ExcelReaderService from '../../services/ExcelReaderService';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css';  
import 'primereact/resources/primereact.min.css';        
import 'primeicons/primeicons.css'; 
import moment from 'moment';
import { toString } from 'lodash';
import AuthService from '../../services/AuthService';
import ToastService from '../../services/ToastService';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ExcelReader = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const toastService = new ToastService(toast); 
  const [fileSelected, setFileSelected] = useState(false);
  const queryParam = useQuery();
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);

  const handleFileUpload = (event) => {
  const file = event.target.files[0];
if (file) {
  setFileSelected(true); 
  const reader = new FileReader();
  reader.onload = (e) => {
    const binaryStr = e.target.result;
    const workbook = XLSX.read(binaryStr, {
      type: 'binary',
      cellDates: true,
    });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(worksheet);
    const formattedData = jsonData.map((row) => {
      Object.keys(row).forEach((key) => {
        if (row[key] instanceof Date) {
          row[key] = moment(row[key]).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        }
      });
      return row;
    });

    setData(formattedData);
  };
  reader.readAsBinaryString(file);
  }
};

const handleSubmit = async () => {
  if (!fileSelected) {
    toastService.showWarning("Warning", "Please select a file before importing.");
    return;
  }

  try {
    setLoading(true);
    const importLeads = data.map((row) => {        
      return {
        adId: toString(row.ad_id),
        adName: toString(row.ad_name),
        adsetId: toString(row.adset_id),
        adsetName: toString(row.adset_name),
        compaignId: toString(row.campaign_id),
        compaignName: toString(row.campaign_name),
        formId: toString(row.form_id),
        formName: toString(row.form_name),
        isOrganic: row.isOrganic,
        platform: toString(row.platform),
        whatsappNumber: toString(row.whatsapp),
        fullName: toString(row.full_name),
        email: toString(row.email),
        city: toString(row.city),
        phoneNumber: toString(row.phone_number),
        leadStatusId: row.leadStatusId,
        createdBy: 0,
        createdOn: new Date(),
        modifiedOn: new Date(),
        modifiedBy: 0,
        isActive: true,
        createdTime: row.created_time
      };
    });

    const response = await ExcelReaderService.createImportLead(importLeads, UserId);
    toastService.showSuccess("Success", "Leads imported successfully!");

  } catch (error) {
    // Display the specific error message from the backend
    toastService.showError("Error", error.message); 
  } finally {
    setLoading(false);
  }
};



  return (
    <div>
       <Toast ref={toast} />      
      <input className='form-control col-lg-4' type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      <Table data={data} />
      <button className='btn btn-save text-white' onClick={handleSubmit}>Import Leads</button>
    </div>
  );
};


const Table = ({ data }) => {
  if (data.length === 0) return <p>No data to display</p>;

  const headers = Object.keys(data[0]);

  return (
    <table>
      <thead>
        <tr>
          {headers.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {headers.map(header => (
              <td key={header}>{row[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ExcelReader;
