import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { Navigate, useNavigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

const AuthService = {
  
    getToken() {
      return localStorage.getItem('accessToken');
    },
  
    setToken(token) {
      localStorage.setItem('accessToken', token); 
    },
  
    removeToken() {
      localStorage.removeItem('accessToken');
    },
  
    getUser() {
      const token = this.getToken();
      if (token) {
        try {
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(
            atob(base64)
              .split('')
              .map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
          );
  
          return JSON.parse(jsonPayload);
        } catch (error) {
          console.error('Error decoding token:', error);
          return null;
        }
      }
      return null;
    },
    async login(username, password, rememberMe, navigate, Roles) {
      const loginPayload = {
        email: username,
        password: password,
      };
    
      try {
        const response = await axios.post(`${API_URL}/Login/Login`, loginPayload);
        const token = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const user = jwtDecode(token);
    
        const userDetail = {
          username: user.UserName,
          UserEmail: user.UserEmail,
          UserId: user.UserId,
          UserRoleId: user.UserRoleId,
        };
    
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('user', JSON.stringify(userDetail));
    
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    
        if (rememberMe) {
          this.setRememberedCredentials(username, password);
        } else {
          this.clearRememberedCredentials();
        }
    
        const roleId = this.getLoggedInRoleId();
        if (roleId === Roles.SuperAdmin || roleId === Roles.Admin) {
            navigate("/registeruser");
         // useNavigate.navigate("/registeruser");
        } else {
          navigate('/dashboard');
        }
      } catch (err) {
        console.error('Login failed', err);
        throw err; 
      }
    },    

      logout() {
        localStorage.removeItem('user');
        localStorage.removeItem("token");
        localStorage.removeItem('hasShownPopup');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('lastActivityTime');
        <Navigate to="/login" /> 
      },
    
      getCurrentUser() {
        const userJson = localStorage.getItem('user');
        var result = userJson ? JSON.parse(userJson) : null;
        return result;
      },
    
      isAuthenticated() {
        return !!localStorage.getItem('accessToken');
      },
      getLoggedInUserId()
      {
        const userJson = localStorage.getItem('user');
        var result = userJson ? JSON.parse(userJson) : null;
        return result ? result.UserId : null;
      },

      getLoggedInRoleId()
      {
        const userJson = localStorage.getItem('user');
          var result = userJson ? JSON.parse(userJson) : null;
        return result ? result.UserRoleId : null;
      },
      async getLastLoginTime() {
        const userId = this.getLoggedInUserId();
        if (!userId) {
            throw new Error("User ID is not available");
        }
        try {
            const response = await axios.get(`${API_URL}/User/lastlogintime/${userId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching last login time', error);
            throw error;
        }
    },
    setRememberedCredentials(email, password) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    },
    getRememberedCredentials() {
      const email = localStorage.getItem("email");
      const password = localStorage.getItem("password");
      return { email, password };
    },
    clearRememberedCredentials() {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    },
    handleLogout()
    {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      <Navigate to="/login" /> 
    },
    setRefreshToken(refreshToken,token,userDetail)
    {
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(userDetail));
    },
    async refreshTokens() 
    {
      const refreshToken = localStorage.getItem('refreshToken');
      const model = { RefreshToken: refreshToken };
      const response = await axios.post(`${API_URL}/Login/RefreshTokens`, model, {
        headers: { 'Content-Type': 'application/json' }
      });
      return response;
    },

    isTokenExpired(token) {
      if (!token) return true;
    
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; 
    
      return decodedToken.exp < currentTime;
    }
    

  };
  

export default AuthService;


